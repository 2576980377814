import { SCHEMAS } from "./collection";

export class Schema {
    schema;
    customErrors;

    constructor(schemaKey, customErrors) {
        this.customErrors = customErrors;
        this.schema = SCHEMAS[schemaKey](customErrors);

        if (!this.schema) {
            throw new Error(`Form schema not found: ${schemaKey}`);
        }
    }

    parseSchema(fields) {
        return this.schema.safeParse(fields);
    }
}
