import { roundIntToQuarter } from "../base";
import { FormValidator } from "./form_validator/form_validator";
import { state } from "./state";
import { formatISODate, parseFormat } from "./utils";

/**
 * Init element repeater functionality.
 */
export function initRepeater() {
    const addButtons = document.querySelectorAll(".repeater-add");

    addButtons.forEach((addButton) => {
        const count = state(1);
        setupRepeater(addButton, count);
    });
}

function setupRepeater(addButton, count) {
    const { repeater } = addButton.dataset;

    const modalElement = document.getElementById("confirm-delete-repeater");
    const repeaterWrapper = document.getElementById(repeater);
    const { validatorId } = repeaterWrapper.dataset;

    addButton.addEventListener("click", () => {
        if (!repeaterWrapper) {
            throw Error("Repeater not found");
        }

        validateRepeater(validatorId, () => {
            handleAddRepeaterItem(repeaterWrapper, count);
        });
    });

    handleRepeaterFocus(repeaterWrapper);
    modalElement.addEventListener("shown.bs.modal", handleRemoveRepeaterItem);
}

const validateRepeater = (validatorId, callback) => {
    if (validatorId == null) {
        callback();
        return;
    }

    const validator = FormValidator.getInstance(validatorId);
    validator.validateForm();

    if (validator.isValid) {
        callback();
    }
};

/**
 * Triggers when remove item modal is shown, removes repeater item entry
 * if user clicks on confirm action and clear listener on dismiss.
 * @param {Event} event
 */
function handleRemoveRepeaterItem(event) {
    const removeItem = document.querySelector(".remove-repeater-item");
    const item = event.relatedTarget.closest(".repeater-item");

    const handleClick = () => {
        item.addEventListener("animationend", item.remove);
        item.classList.add("exiting");
    };

    removeItem.addEventListener("click", handleClick);

    event.target.addEventListener("hidden.bs.modal", () => {
        removeItem.removeEventListener("click", handleClick);
    });
}

/**
 * @param {HTMLElement} repeaterWrapper
 */
function handleAddRepeaterItem(repeaterWrapper, count) {
    const { insertAt } = repeaterWrapper?.dataset ?? "bottom";

    const [getCount, setCount] = count;

    const baseItem = repeaterWrapper.querySelector(".repeater-item-base");

    const inputs = baseItem.querySelectorAll("input,select");

    const newItem = createNewRepeaterItem(baseItem, count);

    const removeRepeaterModal = newItem.querySelector(".remove-repeater-modal");
    removeRepeaterModal.classList.remove("invisible");

    newItem.classList.add("entering");

    if (insertAt === "top") {
        const [first] = [...repeaterWrapper.children];
        repeaterWrapper.insertBefore(newItem, first);
    } else {
        repeaterWrapper.insertBefore(newItem, baseItem);
    }

    const itemValues = generateRepeaterItemValues(inputs);
    insertRepeaterItemValues(newItem, itemValues);

    cleanBaseRepeaterItem(inputs, baseItem);
    baseItem.classList.remove("focused-inputs");
    setCount(getCount() + 1);
}

/**
 * @param {HTMLElement} baseItem
 * @param {[Function, Function]} count
 */
function createNewRepeaterItem(baseItem, count) {
    const [getCount] = count;

    const newItem = document.createElement("div");
    const baseId = baseItem.id.split("-").slice(0, -1).join("-");

    newItem.innerHTML = baseItem.innerHTML;
    const classes = [...baseItem.classList, "row-display"].join(" ");
    const newClasses = classes.replace("repeater-item-base", "repeater-item");

    newItem.setAttribute("class", newClasses);

    newItem.id = `${baseId}-${getCount()}`;
    newItem.innerHTML = baseItem.innerHTML;

    const inputs = newItem.querySelectorAll("input,select");

    inputs.forEach((input) => {
        input.id = `${input.name}-${getCount()}`;
        input.classList.remove("fw-bold");
    });

    return newItem;
}

function generateRepeaterItemValues(inputs) {
    return [...inputs].reduce((prev, curr) => {
        const [value, unit] = parseFormat(curr.dataset.format, curr.value);
        prev[curr.name] = value ? `${value} ${unit}`.trim() : "-";
        return prev;
    }, {});
}

/**
 * Insert all row values in the new repeater item.
 * @param {HTMLElement} item
 * @param {object} values
 */
function insertRepeaterItemValues(item, values) {
    const inputs = item.querySelectorAll("input,select");

    inputs.forEach((input) => {
        if (input.type === "number") {
            input.setAttribute("type", "text");
        }

        input.readOnly = true;
        input.classList.add("input-readonly");
        input.value = values[input.name];
    });
}

/**
 * Reset input values to initial state and unfocus row.
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @param {HTMLElement} baseItem
 */
function cleanBaseRepeaterItem(inputs, baseItem) {
    baseItem.blur();

    inputs.forEach((input) => {
        if (input.tagName === "SELECT") {
            input.selectedIndex = 0;
        } else if (input.type === "date") {
            input.value = formatISODate(new Date());
        } else {
            input.value = "";
        }
    });
}

/**
 * Handle focus repeater items.
 * @param {HTMLElement} repeaterWrapper
 */
function handleRepeaterFocus(repeaterWrapper) {
    const baseItem = repeaterWrapper.querySelector(".repeater-item-base");
    const inputs = baseItem.querySelectorAll("input,select");

    const addFocusedInputsClass = () => {
        baseItem.classList.add("focused-inputs");
    };

    inputs.forEach((input) => {
        input.addEventListener("input", addFocusedInputsClass);
        input.addEventListener("focus", addFocusedInputsClass);

        input.addEventListener("focusout", () => {
            if (!input.value) {
                return;
            }

            const [value] = parseFormat(input.dataset.format, input.value);

            if (input.getAttribute("step") === "0.25") {
                input.value = roundIntToQuarter(value).toString();
            } else if (
                Number.isInteger(parseFloat(input.getAttribute("step")))
            ) {
                input.value = parseInt(value);
            } else {
                input.value = value;
            }
        });
    });
}
