import { AxialExtension } from "./collection/axial_extension";
import { ConfirmFieldExtension } from "./collection/confirm_extension";
import { DependentFieldExtension } from "./collection/dependent_extension";
import { LinkedFieldsExtension } from "./collection/link_extension";
import { LoaderExtension } from "./collection/loader_extension";
import { RepeaterExtension } from "./collection/repeater_extension";
import { UnionExtension } from "./collection/union_extension";

export const EXTENSIONS = Object.freeze({
    link: LinkedFieldsExtension,
    loader: LoaderExtension,
    confirm: ConfirmFieldExtension,
    dependent: DependentFieldExtension,
    axial: AxialExtension,
    union: UnionExtension,
    repeater: RepeaterExtension,
});
