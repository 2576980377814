import { FormValidatorExtension } from "../extension";

export class RepeaterExtension extends FormValidatorExtension {
    beforeSubmit() {
        const { form } = this.formValidator;
        const base = form.querySelector(".repeater-item-base");

        if (!base) {
            return;
        }

        const inputs = base.querySelectorAll("input,select");

        const isEmpty = [...inputs]
            .filter((input) => input.type !== "date")
            .every((input) => {
                return !input.value;
            });

        this.formValidator.validateForm();

        if (this.formValidator.isValid && isEmpty) {
            inputs.forEach((input) => {
                input.disabled = true;
            });
        }
    }
}
